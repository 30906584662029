<template>
  <default-layout>
    <v-container style="max-width: 1200px;">
      <v-row>
        <v-col cols="12">
          <center>
            <h1 class="amber--text font-weight-regular">แนะนำเพื่อน</h1>
            <span class="white--text">
              กรุณาตรวจสอบรายละเอียดอีกครั้งก่อนทำการยืนยัน
            </span>
          </center>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          md="7"
          sm="10"
          cols="12"
        >
          <v-card>
            <div class="pt-5 px-5">
              <center>
                <h3>ลิงค์สำหรับชวนเพื่อน</h3>
              </center>
            </div>
            <v-card-text>
              <v-text-field
                filled
                rounded
                dense
                value="https://betflikbnk.com/"
              />
              <center>
                <v-btn
                  color="green"
                  dark
                  rounded
                  depressed
                  large
                >
                  <v-icon small left>
                    fas fa-copy
                  </v-icon>
                  คัดลอกลิงค์
                </v-btn>
              </center>
              <v-divider class="my-5" />
              <center>
                <span>จำนวนผู้คลิกลิงค์: 0 ครั้ง</span>
              </center>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          md="7"
          sm="10"
          cols="12"
        >
          <v-card>
            <div class="pt-5 px-5">
              <center>
                <h3>รายได้ผู้แนะนำ</h3>
              </center>
              <v-card
                flat
                color="grey ligthen-3"
              >
                <div class="pa-3 my-2">
                  <center>
                    สามารถรับเครดิตผู้แนะนำจากยอด Turnover ได้วันถัดไป
                  </center>
                </div>
              </v-card>
            </div>
            <v-simple-table>
              <thead>
                <tr>
                  <th>ลำดับ</th>
                  <th>ยอดเทิร์น (คาสิโน - สล็อต)</th>
                  <th>สมัคร(บัญชี)</th>
                  <th>ใช้งาน(บัญชี)</th>
                  <th>รายได้</th>
                  <th>สมาชิก</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ชั้นที่ 1</td>
                  <td>0.50% - 0.10%</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>ชั้นที่ 2</td>
                  <td>0.08% - 0.00%</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>ชั้นที่ 3</td>
                  <td>0.02% - 0.10%</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>ชั้นที่ 4</td>
                  <td>0.00% - 0.10%</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>ชั้นที่ 5</td>
                  <td>0.00% - 0.10%</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>-</td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="pa-3">
              <v-divider class="mb-5 px-3" />
              <center>
                <span>
                  รายได้รวม
                  <span class="green--text">0.00</span>
                  เครดิต
                </span>
              </center>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </default-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CommissionPage extends Vue {}
</script>

<style>

</style>
